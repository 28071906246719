import React, { ReactElement, useState, useEffect } from "react"
import SEO from "../../../components/SEO"
import { navigate, PageProps } from "gatsby"
import { useUser, User } from "../../../providers/UserProvider"
import { serverTimestamp } from "firebase/firestore"
import { useForm } from "react-hook-form"
import { useId } from "@reach/auto-id"
import T from "@mui/material/Typography"
import Skeleton from "../../../components/Skeleton"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Button from "../../../components/design-system/Button"
import { ErrorTextWithIcon } from "../../../components/design-system/FormControl/FormControl"
import { Status } from "@ap-frontend/types"
import { BasePageContext } from "../../../interfaces/PageContext"
import { TextWrapper } from "../../../components/ConfidentialityAgreementText"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ButtonWrapper from "../../../components/ButtonWrapper"
import Link from "../../../components/Link"
import useStageStatuses from "../../../hooks/useStageStatuses"

const AgreeCopy = (): ReactElement => {
  return (
    <TextWrapper>
      <T variant="h1" component="h1">
        Submit your application
      </T>
      <T variant="body2">By submitting your application you agree that:</T>
      <List className="MuiList-unordered" component="ul">
        <ListItem>
          <T variant="body2" component="span">
            you have read the{" "}
            <Link to="/terms-and-conditions">terms and conditions</Link>
          </T>
        </ListItem>
        <ListItem>
          <T variant="body2" component="span">
            the information that you have given is true and accurate
          </T>
        </ListItem>
        <ListItem>
          <T variant="body2" component="span">
            {
              "you'll provide any outstanding information or documents as soon as possible"
            }
          </T>
        </ListItem>
        <ListItem>
          <T variant="body2" component="span">
            {
              "you're happy for feedback to be sent to your UCAS referee, if you are still at school"
            }
          </T>
        </ListItem>
      </List>
      <T variant="body2">
        {
          "Once you submit your application, you'll be able to review your answers but you will not be able to make any changes."
        }
      </T>
    </TextWrapper>
  )
}

const AgreeAndSubmitForm = ({ user }: { user: User }): ReactElement => {
  const { activity, updateActivity } = user
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit, formState, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      accuracyConfirmed: activity?.accuracyConfirmed,
    },
  })

  const checked = watch("accuracyConfirmed")
  const feedbackTextId = "UCamConfidentialityFeedback" + useId()

  // When the checkbox is changed, update the activity document
  useEffect(() => {
    if (
      typeof checked === "boolean" &&
      !!user.activity?.accuracyConfirmed !== checked
    ) {
      user.updateActivity("accuracyConfirmed", checked)
    }
  }, [checked, user])

  const onSubmit = () => {
    setSubmitting(true)

    // Don't need to handle redirecting to submitted page, that's done on AgreeAndSubmit
    updateActivity("submissionTimestamp", serverTimestamp()).catch(() => {
      setSubmitting(false)
    })
  }

  const hasErrors =
    !formState.isValid && (formState.isDirty || formState.isSubmitted)

  // Disabled if currently submitting, or we're navigating away to the submitted page
  const disabled = submitting || activity?.submissionTimestamp !== undefined

  return (
    <>
      <AgreeCopy />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl error={hasErrors}>
          <FormControlLabel
            label="I agree"
            className={hasErrors ? "Mui-error" : ""}
            control={
              <Checkbox
                className={hasErrors ? "Mui-error" : undefined}
                inputProps={{
                  "aria-describedby": hasErrors ? feedbackTextId : undefined,
                  "aria-invalid": hasErrors,
                }}
                disabled={disabled}
                {...register("accuracyConfirmed", {
                  required: true,
                })}
                checked={checked}
              />
            }
          />
          {hasErrors && (
            <ErrorTextWithIcon
              id={feedbackTextId}
              data-test-id="accuracy-validation-error"
            >
              {"Select 'I agree' to continue"}
            </ErrorTextWithIcon>
          )}
        </FormControl>
        <ButtonWrapper
          display="flex"
          sx={{
            flexDirection: "row",
            alignItems: "flex-start",
            columnGap: 2,
          }}
        >
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              navigate("/application/submit")
            }}
          >
            Back
          </Button>
          <Button type="submit" disabled={disabled}>
            Submit
          </Button>
        </ButtonWrapper>
      </form>
    </>
  )
}

export const AgreeAndSubmit = ({
  questions,
}: {
  questions: BasePageContext["questions"]
}): ReactElement => {
  const user = AgreeAndSubmit.useUser()
  const stageStatuses = AgreeAndSubmit.useStageStatuses(questions)

  switch (stageStatuses.submit) {
    case undefined:
      // Not fully loaded
      return (
        <>
          <AgreeCopy />
          <FormControl>
            <Skeleton width="30rem" />
          </FormControl>
          <Skeleton variant="rectangular" width="10rem" height="3rem" />
        </>
      )
    case Status.NotStarted:
      // Not yet done the confidentiality declaration
      navigate("/application/submit", { replace: true })
      break
    case Status.CannotStart:
      // Not yet ready to do any of submission process
      navigate("/application", { replace: true })
      break
    case Status.Complete:
      // Submitted
      navigate("/application/submit/complete", { replace: true })
      // Render the form still
      // Stops it briefly flashing away
      return <AgreeAndSubmitForm user={user} />
    case Status.InProgress:
      return <AgreeAndSubmitForm user={user} />
    default:
      throw new Error("app/unexpected-submit-status")
  }

  return <></>
}

AgreeAndSubmit.useUser = useUser
AgreeAndSubmit.useStageStatuses = useStageStatuses

/**
 * The agree and submit page renders a page to confirm that the applicant
 * has understood and agreed that their application is complete and valid
 */
const AgreeAndSubmitPage = ({
  pageContext,
}: PageProps<never, BasePageContext>): ReactElement => {
  return (
    <>
      <SEO title="Submit my application" />
      <AgreeAndSubmit questions={pageContext.questions} />
    </>
  )
}

export default AgreeAndSubmitPage
